import React, { useLayoutEffect, useMemo, useRef } from 'react';
import { Dropdown } from '@balance-web/dropdown-v2';

import { NestedPage } from './types';
import { createDropdownNavigationTrigger } from './NavigationItemDropdownTrigger';
import { NavigationItem } from './NavigationItem';
import { useNavigationContext } from './Navigation';
import { isPageSelected } from './isPageSelected';
import { createTestId } from './utils';

export type NavigationItemWrapperProps = {
  page: NestedPage;
  pathFragment: string;
  visible?: boolean;
};
export const NavigationItemWrapper = ({
  page,
  pathFragment,
  visible,
}: NavigationItemWrapperProps) => {
  const ref = useRef<any>(null);
  const { setNavigationItemRef } = useNavigationContext();

  useLayoutEffect(() => {
    if (ref.current) {
      setNavigationItemRef(page.label, ref.current);
    }
  });

  const isSelected = useMemo(() => isPageSelected(page, pathFragment), [
    page,
    pathFragment,
  ]);

  if (page.options) {
    const dropdownDataAttributes = page.testid
      ? { testid: createTestId(page.testid, 'navigation-dropdown') }
      : {};

    return (
      <Dropdown
        key={page.label}
        verticalOffset={-5}
        align="start"
        data={dropdownDataAttributes}
      >
        <Dropdown.Trigger ref={ref}>
          {createDropdownNavigationTrigger({
            ...page,
            isSelected,
            visible,
          })}
        </Dropdown.Trigger>
        {page.options.map((option) => {
          const optionDataAttributes = option.testid
            ? { testid: option.testid }
            : {};

          return (
            <Dropdown.Item
              key={`${page.label}-${option.label}`}
              href={option.href}
              selected={isPageSelected(option, pathFragment)}
              selectedLabel={option.label}
              data={optionDataAttributes}
            >
              {option.label}
            </Dropdown.Item>
          );
        })}
      </Dropdown>
    );
  }

  return (
    <NavigationItem
      key={page.label}
      href={page.href}
      isSelected={isSelected}
      ref={ref}
      visible={visible}
      testid={page.testid}
    >
      {page.label}
    </NavigationItem>
  );
};
