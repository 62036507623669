import { CSSObject, keyframes } from '@balance-web/core';
import { useTheme } from '@balance-web/theme';

export const useItemStyles = () => {
  const { colors, palette, radii } = useTheme();

  const itemStyles: CSSObject = {
    all: 'unset',
    display: 'flex',
    height: 40,

    fontSize: 13,
    lineHeight: 1,
    color: colors.text,
    borderRadius: radii.small,
    alignItems: 'center',
    position: 'relative',
    userSelect: 'none',
    cursor: 'pointer',

    '&[data-disabled]': {
      backgroundColor: undefined,
      pointerEvents: 'none',
    },

    '&:not([data-disabled])': {
      '&[data-highlighted]': {
        backgroundColor: palette.menuItem.backgroundFocused,
      },
      '&:active': {
        backgroundColor: palette.menuItem.backgroundPressed,
      },

      '&.focus-visible': {
        outline: `2px solid ${palette.global.focusRing}`,
        outlineOffset: 2,
      },
    },
  };

  return itemStyles;
};

export const slideUpAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

export const slideRightAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(-2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

export const slideDownAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

export const slideLeftAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});
