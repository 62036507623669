/* @jsx jsx */
import { jsx } from '@balance-web/core';
import React from 'react';
import { ProductMenu } from '@balance-web/product-menu';
import { Inline } from '@balance-web/inline';
import { Flex } from '@balance-web/flex';
import { IconButton } from '@balance-web/button';
import { useTheme } from '@balance-web/theme';
import { Stack } from '@balance-web/stack';
import { LoadingDots } from '@balance-web/loading';
import { ChevronsLeftIcon } from '@balance-web/icon';
import { List } from '@balance-web/list';
import { Text } from '@balance-web/text';

import { createTestId } from './utils';
import type { Apps } from './types';
import { AppListEmptyState } from './AppListEmptyState';

type AppListProps = {
  apps: Apps;
  isLoading?: boolean;
  onClose: () => void;
  onEmptyStatePrimaryActionClick: () => void;
};
export const AppList = ({
  apps,
  isLoading,
  onClose,
  onEmptyStatePrimaryActionClick,
}: AppListProps) => {
  const { sizing } = useTheme();

  return (
    <ProductMenu>
      <ProductMenu.Header>
        <Inline flex="1" alignY="center">
          <Flex flex="1">
            <ProductMenu.Title>Switch to...</ProductMenu.Title>
          </Flex>
          <IconButton
            icon={ChevronsLeftIcon}
            label="Close product menu"
            weight="none"
            tone="passive"
            onClick={onClose}
          />
        </Inline>
      </ProductMenu.Header>
      <ProductMenu.Body>
        {isLoading ? (
          <Flex alignItems="center" justifyContent="center" flexGrow={1}>
            <Stack align="center">
              <LoadingDots label="Loading apps" />
            </Stack>
          </Flex>
        ) : apps.length ? (
          <List data={{ testid: createTestId('app-switcher') }}>
            {apps.map(
              ({ appName, iconUrl, href, onClick, disabled, testid }) => {
                const data = {
                  testid: !!testid
                    ? createTestId('utility-action', testid)
                    : undefined,
                };
                const isDisabled = disabled;

                return (
                  <List.Item
                    aria-disabled={isDisabled}
                    key={href}
                    startElement={
                      <img
                        css={{
                          height: sizing.small,
                          width: sizing.small,
                        }}
                        src={iconUrl}
                        alt={appName}
                      />
                    }
                    href={href}
                    target="_blank"
                    onClick={onClick}
                    data={data}
                  >
                    <Text
                      size="small"
                      weight="medium"
                      color={(isDisabled && 'dim') || undefined}
                    >
                      {appName}
                    </Text>
                  </List.Item>
                );
              }
            )}
          </List>
        ) : (
          <AppListEmptyState
            onPrimaryActionClick={onEmptyStatePrimaryActionClick}
          />
        )}
      </ProductMenu.Body>
    </ProductMenu>
  );
};
