import React from 'react';
import { Inline } from '@balance-web/inline';

import { createTestId } from './utils';
import type { UtilityActions } from './types';
import { ItemWrapper } from './ItemWrapper';

type UtilityIconsRendererProps = {
  utilityActions?: UtilityActions;
};

export const UtilityActionsRenderer = ({
  utilityActions,
}: UtilityIconsRendererProps) => {
  if (!utilityActions?.length) return null;

  return (
    <Inline gap="xsmall">
      {utilityActions.map(({ Icon, key, onClick, testid }) => {
        const data = {
          testid: !!testid ? createTestId('utility-action', testid) : undefined,
        };

        return (
          <ItemWrapper key={key} as="button" onClick={onClick} data={data}>
            <Icon size="medium" color="muted" />
          </ItemWrapper>
        );
      })}
    </Inline>
  );
};
