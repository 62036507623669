// ts-gql-integrity:7c32ba074705a655e1c2eeadcf589894
/*
ts-gql-meta-begin
{
  "hash": "86fd5a5dcf8b6efbf5bbe7a3fea637f4"
}
ts-gql-meta-end
*/

import * as SchemaTypes from "./@schema";
import { TypedDocumentNode } from "@ts-gql/tag";

type RefreshAuthTokenMutationMutationVariables = {
  refreshToken: SchemaTypes.Scalars['String'];
};


type RefreshAuthTokenMutationMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly reAuthenticatePortalUser: SchemaTypes.Maybe<(
    { readonly __typename: 'AuthenticatedToken' }
    & Pick<SchemaTypes.AuthenticatedToken, 'token' | 'refreshToken'>
  )> }
);


export type type = TypedDocumentNode<{
  type: "mutation";
  result: RefreshAuthTokenMutationMutation;
  variables: RefreshAuthTokenMutationMutationVariables;
  documents: SchemaTypes.TSGQLDocuments;
}>

declare module "./@schema" {
  interface TSGQLDocuments {
    RefreshAuthTokenMutation: type;
  }
}

export const document = JSON.parse("{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"OperationDefinition\",\"operation\":\"mutation\",\"name\":{\"kind\":\"Name\",\"value\":\"RefreshAuthTokenMutation\"},\"variableDefinitions\":[{\"kind\":\"VariableDefinition\",\"variable\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"refreshToken\"}},\"type\":{\"kind\":\"NonNullType\",\"type\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"String\"}}},\"directives\":[]}],\"directives\":[],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"reAuthenticatePortalUser\"},\"arguments\":[{\"kind\":\"Argument\",\"name\":{\"kind\":\"Name\",\"value\":\"refreshToken\"},\"value\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"refreshToken\"}}}],\"directives\":[],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"token\"},\"arguments\":[],\"directives\":[]},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"refreshToken\"},\"arguments\":[],\"directives\":[]}]}}]}}]}")
