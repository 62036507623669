import { gql } from '@ts-gql/tag';

import type { TokenRefreshHandler } from '@reckon-web/gql-api-client';

const RefreshAuthTokenMutation = gql`
  mutation RefreshAuthTokenMutation($refreshToken: String!) {
    reAuthenticatePortalUser(refreshToken: $refreshToken) {
      token
      refreshToken
    }
  }
` as import('../../../__generated__/ts-gql/RefreshAuthTokenMutation').type;

export type RefreshTokenMutationProps = typeof RefreshAuthTokenMutation['___type']['variables'];
export type RefreshTokenMutationResult = typeof RefreshAuthTokenMutation['___type']['result']['reAuthenticatePortalUser'];

export const refreshAuthTokenMutation: TokenRefreshHandler = async ({
  client,
  refreshToken,
}) => {
  const { data, errors } = await client.mutate({
    // @ts-ignore
    mutation: RefreshAuthTokenMutation,
    variables: {
      refreshToken,
    },
  });

  if (errors || !data?.reAuthenticatePortalUser) {
    return null;
  }
  return data.reAuthenticatePortalUser;
};
