/** @jsx jsx */
import React, { useEffect, useMemo } from 'react';
import { jsx } from '@balance-web/core';
import { Dropdown } from '@balance-web/dropdown-v2';
import { Text } from '@balance-web/text';
import { Divider } from '@balance-web/divider';
import {
  ExternalLinkIcon,
  GridIcon,
  HelpCircleIcon,
  LogOutIcon,
} from '@balance-web/icon';

import { AppHeader } from '@reckon-web/app-header';

import { getEncodedProductLauncherURL } from '../../../utils';
import { Config } from '../../../services/Config';
import { useAuth, useAuthenticatedUser } from '../../../services/Auth';
import { AppName, PortalURLs } from '../../../constants';

export const SiteHeaderContainer = () => {
  const { logout } = useAuth();
  const { user, isLoading } = useAuthenticatedUser();

  const userFullName = user?.fullName!;
  const userEmail = user?.email!;
  const tenancyIconURL = user?.tenancyIconURL!;
  const tenancy = user?.tenancyId!;

  useEffect(() => {
    (function changeFavicon(src: string) {
      var link = document.createElement('link'),
        oldLink = document.getElementById('dynamic-favicon');
      link.id = 'dynamic-favicon';
      link.rel = 'shortcut icon';
      link.href = src;
      if (oldLink) {
        document.head.removeChild(oldLink);
      }
      document.head.appendChild(link);
    })(tenancyIconURL);
  }, [tenancyIconURL]);

  const appName: string = useMemo(() => {
    return AppName[tenancy];
  }, [tenancy]);

  const handleLogoutClick = () => {
    logout();
    // We're doing redirect here because we know the user tenancy here and we can redirect to the right login url.
    window.location.href = getEncodedProductLauncherURL(tenancy);
  };

  return (
    <AppHeader
      appIconUrl={tenancyIconURL}
      appName={appName}
      isLoading={isLoading}
      appVersion={Config.APPLICATION_VERSION}
      userMenu={
        userFullName && userEmail
          ? {
              userFullName,
              userEmail,
              items: (
                <React.Fragment>
                  <Dropdown.Item
                    startElement={<HelpCircleIcon size="small" color="muted" />}
                    href={Config.APPLICATION_HELP_AND_SUPPORT_URL}
                    target="_blank"
                  >
                    Help & Support
                  </Dropdown.Item>

                  <Dropdown.Item
                    startElement={<GridIcon size="small" color="muted" />}
                    endElement={<ExternalLinkIcon size="small" color="muted" />}
                    href={PortalURLs[tenancy]}
                    target="_blank"
                  >
                    <Text>Go to Portal</Text>
                  </Dropdown.Item>
                  <Divider />
                  <Dropdown.Item
                    startElement={<LogOutIcon size="small" color="muted" />}
                    onSelect={handleLogoutClick}
                  >
                    <Text>Sign out</Text>
                  </Dropdown.Item>
                </React.Fragment>
              ),
            }
          : undefined
      }
    />
  );
};
