import React, { ReactNode } from 'react';

import { useAuth } from '@reckon-web/auth-store';

import { Loader } from '../../components/common/Loader';
import { getEncodedProductLauncherURL } from '../../utils';

import { useGetCurrentUserQuery } from './useGetCurrentUserQuery';
import { AuthenticatedUserContext } from './AuthenticatedUserContext';

type AuthenticatedUserProviderProps = {
  children: ReactNode;
};
export function AuthenticatedUserProvider({
  children,
}: AuthenticatedUserProviderProps) {
  const { isAuthenticated } = useAuth();
  const { user, isLoading, error } = useGetCurrentUserQuery({
    skip: !isAuthenticated,
  });

  if (!isAuthenticated) {
    window.location.href = getEncodedProductLauncherURL();
    // This is to show the loader while it's redirecting user to portal. Instead of showing empty screen.
    return <Loader label="Loading" />;
  }

  return (
    <AuthenticatedUserContext.Provider value={{ user, isLoading, error }}>
      {children}
    </AuthenticatedUserContext.Provider>
  );
}
