import type { NestedPage } from './types';

export const isPageSelected = (
  page: NestedPage,
  selectedPage: string
): boolean => {
  if (!page.options) {
    return page.match.includes(selectedPage);
  }

  return !!page.options.reduce((acc, page) => {
    return acc || isPageSelected(page, selectedPage);
  }, false);
};
