/** @jsx jsx */
import type { ReactNode } from 'react';
import { buildDataAttributes, jsx } from '@balance-web/core';
import { flattenElements } from '@balance-web/utils';
import type { WithDataAttributeProp } from '@balance-web/core';

import { ListItem } from '../ListItem';
import { useListSeparatorStyles } from '../styles';
import { useHideItemBorderOnHover } from '../useHideItemBorderOnHover';

export type ListProps = WithDataAttributeProp<{
  children: ReactNode;
}>;

export const List = ({ data, children }: ListProps) => {
  const childArray = flattenElements(children);

  const childRefs = useHideItemBorderOnHover({ children });
  const listSeparatorStyles = useListSeparatorStyles();

  const dataAttributes = buildDataAttributes(data);

  return (
    <ul {...dataAttributes}>
      {childArray.map((child, index) => {
        const key = index;
        const isThisItemSelected = !!child.props['selected'];
        const isNextItemSelected =
          index < childArray.length - 1 &&
          !!childArray[index + 1].props['selected'];

        const nonSelectedListItemSeparatorStyles =
          isThisItemSelected || isNextItemSelected ? {} : listSeparatorStyles;

        return (
          <li
            key={key}
            ref={(element) => (childRefs.current[index] = element)}
            css={nonSelectedListItemSeparatorStyles}
          >
            {child}
          </li>
        );
      })}
    </ul>
  );
};

List.Item = ListItem;
