import { createContext, useContext } from 'react';

const RadioGroupContext = createContext(false);

export const RadioGroupContextProvider = RadioGroupContext.Provider;

export const useRadioGroupContext = () => {
  return useContext(RadioGroupContext);
};

const TestIdContext = createContext<string | undefined>(undefined);

export const TestIdContextProvider = TestIdContext.Provider;

export const useTestId = () => {
  return useContext(TestIdContext);
};
