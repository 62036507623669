import React, {
  ReactNode,
  Ref,
  cloneElement,
  forwardRef,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { useForkedRef } from '@balance-web/utils';
import type { WithDataAttributeProp } from '@balance-web/core';
import { buildDataAttributes } from '@balance-web/core';

import { useTestId } from '../context';

export type CustomTriggerProps = {
  isOpen: boolean;
  triggerProps: WithDataAttributeProp<{ ref: Ref<any> }>;
};

export type TriggerProps = WithDataAttributeProp<{
  children?: ReactNode | ((props: CustomTriggerProps) => ReactNode);
}>;

export const Trigger = forwardRef<TriggerProps, any>(
  ({ children, data }, ref) => {
    if (children === undefined || children === null) throw Error('zz');

    const [isOpen, setIsOpen] = useState(false);
    const exposedTriggerRef = useRef<any>(null);
    const composedRef = useForkedRef(exposedTriggerRef, ref);

    const dataAttributes = buildDataAttributes(data);

    const parentTestId = useTestId();
    const triggerTestIdProp = parentTestId
      ? { 'data-testid': `${parentTestId}-trigger` }
      : {};

    const childrenElement =
      typeof children === 'function'
        ? children({
            isOpen,
            triggerProps: {
              ref: composedRef,
              ...dataAttributes,
              ...triggerTestIdProp,
            },
          })
        : cloneElement(children, { ...dataAttributes, ...triggerTestIdProp });

    /**
     * Radix only provides open/closed state through data-attributes so we have to observe
     * the element attributes and provide open/closed props ourselves.
     * https://www.radix-ui.com/docs/primitives/components/dropdown-menu#trigger
     */
    useLayoutEffect(() => {
      if (
        typeof children === 'function' &&
        exposedTriggerRef.current !== null
      ) {
        var observer = new MutationObserver((mutations) => {
          mutations.forEach(function (mutation) {
            if (
              mutation.type === 'attributes' &&
              mutation.attributeName === 'aria-expanded'
            ) {
              setIsOpen(
                exposedTriggerRef.current?.getAttribute('aria-expanded') ===
                  'true'
              );
            }
          });
        });

        observer.observe(exposedTriggerRef.current, {
          attributes: true,
        });

        return () => {
          observer.disconnect();
        };
      }
    }, [children]);

    return (
      <DropdownMenuPrimitive.Trigger asChild>
        {childrenElement}
      </DropdownMenuPrimitive.Trigger>
    );
  }
);
