import { BalanceThemeRaw, BalanceThemeWithPalette } from '@balance-web/theme';
import { getContrastText, hexToRgb } from '@balance-web/utils';

export const buttonSizeValues = ['small', 'medium'] as const;
export const buttonToneValues = ['critical', 'active', 'passive'] as const;
export const buttonWeightValues = ['bold', 'subtle', 'none'] as const;

export type Size = typeof buttonSizeValues[number];
export type Tone = typeof buttonToneValues[number];
export type Weight = typeof buttonWeightValues[number];

export type ButtonStylesProps = {
  weight: Weight;
  tone: Tone;
  borderRadius?: number | string;
  fontWeight?: number;
  disabled?: boolean;
  loading?: boolean;
  block?: boolean;
  size: SizePack;
};

export type SizePack = {
  fontSizeDisplay: string;
  fontSizeText: string;
  borderRadius: string;
  boxSize: string;
  gap: string;
  paddingX: string;
  paddingY: string;
};

export const getButtonStyles = (
  {
    weight,
    tone,
    block = false,
    borderRadius,
    fontWeight,
    size,
  }: ButtonStylesProps,
  theme: BalanceThemeWithPalette,
  rawTheme: BalanceThemeRaw
) => {
  const baseStyles = {
    alignItems: 'center',
    backgroundColor: 'white',
    border: 0,
    borderRadius,
    boxSizing: 'border-box',
    cursor: 'pointer',
    display: block ? 'flex' : 'inline-flex',
    flexShrink: 0, // button text should NOT wrap, even within a flex container
    fontFamily: theme.typography.fontFamily.body,
    fontWeight,
    height: size?.boxSize,
    justifyContent: 'center',
    outline: 0,
    paddingBottom: 0,
    paddingTop: 0,
    position: 'relative',
    textDecoration: 'none',
    userSelect: 'none',
    whiteSpace: 'nowrap',
    width: block ? '100%' : undefined,

    // size properties
    fontSize: size.fontSizeText,
    paddingLeft: size.paddingX,
    paddingRight: size.paddingX,

    // cover all cases by desaturating and lowering opacity
    '&[aria-disabled=true]': {
      cursor: 'default',
      filter: 'grayscale(100%)',
      mixBlendMode: 'luminosity', // pick up the background hue to avoid harsh neutrals
      opacity: 0.6,
    },

    '&:not([aria-disabled=true]).focus-visible': {
      boxShadow: `0 0 0 2px ${theme.palette.global.focusRing}`,
    },
  };

  const tonalColor = rawTheme.palette.actions[tone];
  const contrastText = getContrastText(tonalColor);

  if (weight === 'subtle') {
    return {
      ...baseStyles,
      backgroundColor: hexToRgb(tonalColor, 0.1),
      color: tonalColor,

      '&:not([aria-disabled=true])': {
        ':hover': {
          backgroundColor: hexToRgb(tonalColor, 0.2),
        },
        ':active': {
          backgroundColor: hexToRgb(tonalColor, 0.3),
        },
      },
    };
  } else if (weight === 'none') {
    return {
      ...baseStyles,
      backgroundColor: 'transparent',
      color: tonalColor,

      '&:not([aria-disabled=true])': {
        ':hover': {
          backgroundColor: hexToRgb(tonalColor, 0.1),
        },
        ':active': {
          backgroundColor: hexToRgb(tonalColor, 0.2),
        },
      },
    };
  } else {
    // bold button
    return {
      ...baseStyles,
      backgroundColor: tonalColor,
      color: contrastText,

      '&:not([aria-disabled=true])': {
        ':hover': {
          boxShadow: theme.shadow.xsmall,
          backgroundColor: theme.palette.button[tone].backgroundFocused,
        },
        ':active': {
          boxShadow: 'none',
          transform: 'translateY(1px)',
          backgroundColor: theme.palette.button[tone].backgroundPressed,
        },
      },
    };
  }
};
