import React from 'react';
import type { PropsWithChildren } from 'react';
import { useRouter } from 'next/router';
import useHashParam from 'use-hash-param';

import { useAuth } from '@reckon-web/auth-store';

import { isSecureRoute } from '../Navigation';
export type UrlFragmentManagerProps = PropsWithChildren<{}>;

export const UrlFragmentManager = ({ children }: UrlFragmentManagerProps) => {
  const router = useRouter();
  const [accessToken, setAccessToken] = useHashParam('accessToken', '');
  const [refreshToken] = useHashParam('refreshToken', '');

  const { credentials, setCredentials, isLoading, setIsLoading } = useAuth();

  React.useEffect(() => {
    if (!isSecureRoute(router.pathname)) {
      setIsLoading(false);
      return;
    }

    if (!!isLoading && !!accessToken && accessToken !== 'null') {
      setCredentials({ accessToken, refreshToken });
      setAccessToken('');
      setIsLoading(false);
      return;
    }

    if (isLoading) {
      setIsLoading(false);
    }
  }, [
    accessToken,
    credentials?.accessToken,
    isLoading,
    refreshToken,
    router.pathname,
    setAccessToken,
    setCredentials,
    setIsLoading,
  ]);

  return <>{children}</>;
};
