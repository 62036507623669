import { AppLauncherURLs, PortalURLs } from '../constants';

/**
 * Retrieves the encoded app launcher URL for the specified user tenancy.
 *
 * @param {string} userTenancy - The user tenancy. Defaults to 'RECKON' if not provided because user is not authenticated.
 * @returns {string} - The encoded app launcher URL for the user tenancy.
 */

export const getEncodedProductLauncherURL = (
  userTenancy: string = 'RECKON'
): string => {
  const redirectURL = `${
    PortalURLs[userTenancy]
  }#?redirectURL=${encodeURIComponent(AppLauncherURLs[userTenancy])}`;

  return redirectURL;
};
