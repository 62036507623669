import { HydratableCredentials } from './AuthContext';

/**
 * Generates request headers for api requests based on available credentials
 */
export function createRequestHeaders(
  credentials?: Partial<HydratableCredentials>
): Record<string, string> {
  if (!!credentials?.accessToken) {
    return {
      authorization: `Bearer ${credentials.accessToken}`,
    };
  }
  return {};
}
