/** @jsx jsx */

import { ComponentType } from 'react';
import { jsx } from '@balance-web/core';
import { useRawTheme, useTheme } from '@balance-web/theme';
import { forwardRefWithAs } from '@balance-web/utils';
import { IconProps } from '@balance-web/icon';
import { buildDataAttributes } from '@balance-web/core';
import type { WithDataAttributeProp } from '@balance-web/core';

import {
  ButtonStylesProps,
  Size,
  Tone,
  Weight,
  getButtonStyles,
} from './styles';
import { usePreventableClickHandler } from './utils';

type IconButtonProps = WithDataAttributeProp<{
  /** The label of the button, passed to `aria-label`. */
  label: string;
  /** The weight of the button. */
  weight?: Weight;
  /** When true, the button will be disabled. */
  disabled?: boolean;
  /** The icon to place in the button */
  icon: ComponentType<IconProps>;
  /** The tone that is conveyed by the button. */
  tone?: Tone;
  /** Provide an alternate type if the button is within a form. */
  type?: HTMLButtonElement['type'];
  /** The size of the button. */
  size?: Size;
}>;

export const IconButton = forwardRefWithAs<'button', IconButtonProps>(
  (
    {
      as: Tag = 'button',
      disabled = false,
      icon: Icon,
      label,
      size = 'medium',
      tone = 'active',
      weight = 'bold',
      data,
      ...props
    },
    ref
  ) => {
    const theme = useTheme();
    const rawTheme = useRawTheme();

    if (Tag === 'button') {
      props.type = props.type || 'button';
    }

    // styles
    const state: ButtonStylesProps = {
      borderRadius: '50%',
      disabled,
      size: {
        borderRadius: theme.radii[size],
        boxSize: theme.sizing[size === 'small' ? 'small' : 'base'],
        fontSizeDisplay:
          theme.typography.fontSize[size === 'small' ? 'xsmall' : 'large'],
        fontSizeText: theme.typography.fontSize[size],
        gap: theme.spacing.small,
        paddingX: theme.spacing[size === 'small' ? 'medium' : 'large'],
        paddingY: theme.spacing[size === 'small' ? 'xsmall' : 'small'],
      },
      tone,
      weight,
    };
    const buttonStyles = {
      ...getButtonStyles(state, theme, rawTheme),
      width: state.size.boxSize,
      height: state.size.boxSize,
      padding: 0, // remove native <button/> padding so icons are centered correctly
    };

    // handle "disabled" behaviour w/o disabling buttons
    const handleClick = usePreventableClickHandler(props, disabled);

    const dataAttributes = buildDataAttributes(data);

    return (
      <Tag
        css={buttonStyles}
        aria-disabled={disabled}
        aria-label={label}
        ref={ref}
        {...dataAttributes}
        {...props}
        // must be after prop spread
        onClick={handleClick}
      >
        <Icon size={size} />
      </Tag>
    );
  }
);
