import React, { useCallback, useMemo } from 'react';
import '@ts-gql/apollo';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  NormalizedCacheObject,
} from '@apollo/client';

import { isSSR } from '@reckon-web/next-is-ssr';
import { useAuth } from '@reckon-web/auth-store';
import type { HydratableCredentials } from '@reckon-web/auth-store';

import { HandleAuthErrorResponseLink } from './HandleAuthErrorResponseLink';
import { AnnotateRequestHeadersLink } from './AnnotateRequestHeadersLink';
import { createPrefixLink } from './PrefixLink';
import type { ResultSchema } from './Cache';
import { createCache } from './Cache';

export type TokenRefreshHandler = (input: {
  client: ApolloClient<NormalizedCacheObject>;
  refreshToken: string;
}) => Promise<HydratableCredentials>;

type GqlApiClientProviderProps = {
  url: string;
  name: string;
  operationPrefixNameForAddingToRawLogOutputs: string;
  version: string;
  schema: ResultSchema;
  children: React.ReactNode;
  onTokenRefresh: TokenRefreshHandler;
  isAuthError?: (error?: string) => boolean;
};

export const GqlApiClientProvider = ({
  url,
  name,
  operationPrefixNameForAddingToRawLogOutputs,
  version,
  schema,
  children,
  onTokenRefresh,
  isAuthError,
}: GqlApiClientProviderProps) => {
  const {
    credentials,
    requestHeaders,
    setCredentials,
    removeCredentials,
    logout,
  } = useAuth();

  const handleUnauthenticatedError = useCallback(
    async (error?: Error | string | undefined) => {
      if (!credentials?.refreshToken) {
        removeCredentials();
        logout();
        return;
      }

      try {
        const newCredentials = await onTokenRefresh({
          client,
          refreshToken: credentials.refreshToken,
        });

        if (newCredentials) {
          setCredentials(newCredentials);
        } else {
          throw new Error('no credentials');
        }
      } catch (error) {
        removeCredentials();
        logout();
      }
    },
    [
      credentials?.refreshToken,
      logout,
      onTokenRefresh,
      removeCredentials,
      setCredentials,
    ]
  );

  const routeAuthError = useCallback(
    async (error?: 'UNAUTHENTICATED' | Error | string | undefined) => {
      if (error === 'UNAUTHENTICATED') {
        await handleUnauthenticatedError(error);
        return;
      }
    },
    [handleUnauthenticatedError]
  );

  const prefixLink = useMemo(() => {
    return createPrefixLink({
      name: operationPrefixNameForAddingToRawLogOutputs,
    });
  }, [operationPrefixNameForAddingToRawLogOutputs]);

  const client = useMemo(() => {
    return new ApolloClient({
      name,
      version,
      cache: createCache({ schema }),
      link: ApolloLink.from([
        prefixLink,
        HandleAuthErrorResponseLink(routeAuthError, isAuthError),
        AnnotateRequestHeadersLink({ requestHeaders }),
        new HttpLink({
          uri: url,
          fetch:
            typeof window === 'undefined' ? () => new Promise(() => {}) : fetch,
        }),
      ]),
    });
  }, [
    isAuthError,
    name,
    prefixLink,
    // use a proper json stable hashing lib if/when this object gets large
    JSON.stringify(requestHeaders),
    routeAuthError,
    schema,
    url,
    version,
  ]);

  if (isSSR()) return <></>;

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
