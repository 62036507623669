import { OperationDefinitionNode } from 'graphql';
import { ApolloLink } from '@apollo/client';

type PrefixLinkFactoryProps = {
  name: string;
};
export function createPrefixLink({ name }: PrefixLinkFactoryProps) {
  return new ApolloLink((operation, next) => {
    operation.operationName = `${name}${operation.operationName}`;
    operation.query = {
      ...operation.query,
      definitions: operation.query.definitions.map((node) => {
        if (node.kind === 'OperationDefinition') {
          let operationNode: OperationDefinitionNode = {
            ...node,
            name: {
              kind: 'Name',
              value: `${name}${node.name?.value}`,
            },
          };
          return operationNode;
        }
        return node;
      }),
    };
    return next(operation);
  });
}
