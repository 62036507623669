/** @jsx jsx */

import { jsx } from '@balance-web/core';
import { useTheme } from '@balance-web/theme';

import { CheckboxSize } from './types';

const sizeToScaleKey = {
  small: 'xxsmall',
  medium: 'xsmall',
} as const;

export const CheckboxIndicator = ({
  size = 'medium',
  ...props
}: {
  children?: React.ReactNode;
  size?: CheckboxSize;
}) => {
  const { palette, radii, sizing } = useTheme();
  const resolvedSize = sizing[sizeToScaleKey[size]];

  return (
    <div
      css={{
        alignItems: 'center',
        backgroundColor: palette.formControl.background,
        border: `2px solid ${palette.formControl.border}`,
        borderRadius: radii.xsmall,
        boxSizing: 'border-box',
        color: palette.formControl.background,
        cursor: 'pointer',
        display: 'flex',
        flexShrink: 0,
        height: resolvedSize,
        justifyContent: 'center',
        width: resolvedSize,

        'input:hover + &': {
          borderColor: palette.formControl.interaction,
        },
        'input:focus + &, input:active + &': {
          borderColor: palette.formControl.interaction,
        },
        'input:focus + &': {
          boxShadow: `0 0 0 2px ${palette.global.focusRing}`,
        },

        'input:checked + &, input:indeterminate + &': {
          backgroundColor: palette.formControl.interaction,
          borderColor: palette.formControl.interaction,
          color: palette.formControl.foregroundChecked,
        },
        'input:disabled + &': {
          backgroundColor: palette.formControl.border,
          borderColor: palette.formControl.border,
          color: palette.formControl.border,
          cursor: 'default',
        },
        'input:checked:disabled + &': {
          color: palette.formControl.foregroundDisabled,
        },
      }}
      {...props}
    />
  );
};
