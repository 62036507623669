/** @jsx jsx  */
import { Inline } from '@balance-web/inline';
import { jsx } from '@balance-web/core';
import { useTheme } from '@balance-web/theme';
import { Stack } from '@balance-web/stack';
import { Text } from '@balance-web/text';
import { ChevronDownIcon } from '@balance-web/icon';
import { truncateText } from '@balance-web/utils';
import { useCallback } from 'react';
import { Tooltip } from '@balance-web/tooltip';

import { createTestId } from './utils';

type AppInfoProps = {
  /** Icon of the app. */
  appIconUrl: string;
  /** Name of the app. */
  appName: string;
  /** Name of the book being used. */
  bookName?: string;
  onClick?: () => void;
};
export const AppInfo = ({
  appIconUrl,
  appName,
  bookName,
  onClick,
}: AppInfoProps) => {
  const { palette, sizing } = useTheme();

  const BookName = useCallback(() => {
    if (!bookName?.length) {
      return null;
    }

    const truncatedBookName = truncateText(bookName, 15);

    const bookNameText = (
      <Text
        color="muted"
        size="xsmall"
        css={{ lineHeight: 1 }}
        data={{ testid: createTestId('book-name') }}
      >
        {truncatedBookName}
      </Text>
    );

    if (truncatedBookName === bookName) {
      return bookNameText;
    }

    return <Tooltip content={bookName}>{bookNameText}</Tooltip>;
  }, [bookName]);

  const AppName = useCallback(() => {
    const truncatedAppName = truncateText(appName, 15);

    const appNameText = (
      <Text
        weight="bold"
        css={{ lineHeight: 1 }}
        data={{ testid: createTestId('app-name') }}
      >
        {truncatedAppName}
      </Text>
    );

    if (truncatedAppName === appName) {
      return appNameText;
    }

    return <Tooltip content={appName}>{appNameText}</Tooltip>;
  }, [appName]);

  return (
    <Inline
      as={onClick && 'button'}
      data-testid="app-header-book-info"
      gap="small"
      paddingX="small"
      paddingY="xsmall"
      borderRadius="small"
      alignY="center"
      onClick={onClick}
      css={
        onClick && {
          ':hover': {
            background: palette.actionButton.backgroundFocused,
          },
          '&.focus-visible': {
            background: palette.actionButton.backgroundFocused,
          },
          ':active': {
            background: palette.actionButton.backgroundPressed,
          },
        }
      }
    >
      <img
        src={appIconUrl}
        alt={appName}
        css={{
          height: sizing.small,
          width: sizing.small,
        }}
      />
      <Stack align="left" gap="xsmall">
        <AppName />
        <BookName />
      </Stack>
      {onClick && <ChevronDownIcon size="small" color="muted" />}
    </Inline>
  );
};
