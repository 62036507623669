import { setContext } from '@apollo/client/link/context';
import { v4 as uuid4 } from 'uuid';

type AnnotateRequestHeadersLinkProps = {
  requestHeaders?: Record<string, string>;
};

export function AnnotateRequestHeadersLink({
  requestHeaders,
}: AnnotateRequestHeadersLinkProps) {
  return setContext(async (_, previousContext) => {
    // stored as separate varirable to make debugging easier
    const output = {
      ...(previousContext || {}),
      headers: {
        // RWR-1783: allow tracing through reckons ecosystem
        'x-rkn-correlation-id': uuid4(),
        ...(previousContext?.headers || {}),
        ...(requestHeaders || {}),
      },
    };

    return output;
  });
}
