/** @jsx jsx */
import { forwardRef } from 'react';
import { jsx } from '@balance-web/core';
import { ActionButton, ActionButtonProps } from '@balance-web/button';
import { ChevronDownIcon, ChevronUpIcon } from '@balance-web/icon';

export const TriggerButton = forwardRef<any, ActionButtonProps>(
  (props, ref) => {
    if ('children' in props) {
      return <ActionButton ref={ref} type="button" {...props} />;
    }

    return (
      <ActionButton
        ref={ref}
        type="button"
        {...props}
        iconAfter={props['aria-expanded'] ? ChevronUpIcon : ChevronDownIcon}
      />
    );
  }
);
