import { ReactTagManager } from 'react-gtm-ts';

export type LoadGoogleTagManagerServiceProps = {
  apiKey: string;
};

export async function LoadGoogleTagManagerService({
  apiKey,
}: LoadGoogleTagManagerServiceProps) {
  ReactTagManager.init({
    code: apiKey,
  });
}
