import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocalstorageState } from 'rooks';

import { gtmEvent } from '@reckon-web/google-tag-manager';

import { AuthContext, HydratableCredentials } from './AuthContext';
import { AuthProviderIsLoading } from './AuthProviderIsLoading';
import { createRequestHeaders } from './createRequestHeaders';
export type AuthProviderProps = React.PropsWithChildren<{
  /**
   * localstorage key prefix to store credentials
   */
  storagePrefix: string;

  /**
   * When credentials change, generate some request headers.
   *
   * When no function is provided AuthProvider will by default use `createRequestHeaders`.
   *
   * If you provide a function, then only the output of that function will be used.
   *
   * @see [createRequestHeaders]
   */
  getRequestHeaders?: typeof createRequestHeaders;

  /* logic to run when logout is called from useAuth. This is called after we remove credentials from localstorage. */
  onLogout: () => void;

  clientId?: string;
}>;

/**
 * AuthProvider
 */
export function AuthProvider({
  children,
  storagePrefix,
  clientId,
  onLogout,
  getRequestHeaders,
}: AuthProviderProps) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [
    credentials,
    saveCredentials,
    removeCredentials,
  ] = useLocalstorageState<any>(`${storagePrefix}_credentials`);

  const requestHeaders = useMemo(() => {
    return typeof getRequestHeaders === 'function'
      ? getRequestHeaders(credentials)
      : createRequestHeaders(credentials);
  }, [getRequestHeaders, credentials]);

  const logout = useCallback(async () => {
    removeCredentials();
    gtmEvent('logout', { clientId });
    onLogout();
  }, [clientId, onLogout, removeCredentials]);

  const isAuthenticated = useMemo(() => {
    const { accessToken } = credentials || {};
    return !!accessToken && accessToken !== 'null';
  }, [credentials]);

  const setCredentials: Dispatch<SetStateAction<
    HydratableCredentials | undefined
  >> = useCallback(
    (value) => {
      saveCredentials(typeof value === 'function' ? value(credentials) : value);
    },
    [credentials, saveCredentials]
  );

  useEffect(() => {
    if (isAuthenticated) {
      setIsLoading(false);
    }
  }, [isAuthenticated]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isLoading,
        requestHeaders,
        credentials,
        setCredentials,
        setIsLoading,
        removeCredentials,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.IsLoading = AuthProviderIsLoading;
