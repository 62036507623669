/** @jsx jsx */
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { MenuContentProps } from '@radix-ui/react-dropdown-menu';
import { ReactNode } from 'react';
import {
  WithDataAttributeProp,
  getTestIdFromDataAttributes,
  jsx,
} from '@balance-web/core';
import { flattenElements } from '@balance-web/utils';

import { Trigger } from './radix-styled-components';
import { Content, Divider, SubMenuTrigger } from './radix-styled-components';
import { Item } from './Item';
import { RadioGroup } from './RadioGroup';
import { RadioItem } from './RadioItem';
import { GroupTitle } from './GroupTitle';
import { MenuTitle } from './MenuTitle';
import { SubMenu } from './SubMenu';
import { TriggerButton } from './TriggerButton';
import { TestIdContextProvider } from './context';

export type DropdownProps = WithDataAttributeProp<
  {
    children: ReactNode;
    verticalOffset?: MenuContentProps['sideOffset'];
  } & Pick<MenuContentProps, 'align'>
>;

export const Dropdown = ({
  children,
  verticalOffset,
  align,
  data,
  ...props
}: DropdownProps) => {
  const childArray = flattenElements(children);

  /**
   * We render like this to reduce the number of primitives consumers have to deal with.
   * If, at some point doing this is costing us flexibility we might have to let consumers opt into
   * the Content primitive.
   */
  const triggerElement = childArray.find((child) => child.type === Trigger);
  const nonTriggerElements = childArray.filter(
    (child) => child.type !== Trigger
  );

  const testId = getTestIdFromDataAttributes(data);

  return (
    <TestIdContextProvider value={testId}>
      <DropdownMenuPrimitive.Root {...props}>
        {triggerElement}
        <Content sideOffset={verticalOffset} align={align} data={data}>
          {nonTriggerElements}
        </Content>
      </DropdownMenuPrimitive.Root>
    </TestIdContextProvider>
  );
};
Dropdown.Trigger = Trigger;
Dropdown.TriggerButton = TriggerButton;
Dropdown.SubMenu = SubMenu;
Dropdown.SubMenuTrigger = SubMenuTrigger;
Dropdown.Item = Item;
Dropdown.RadioGroup = RadioGroup;
Dropdown.RadioItem = RadioItem;
Dropdown.Divider = Divider;
Dropdown.GroupTitle = GroupTitle;
Dropdown.MenuTitle = MenuTitle;
